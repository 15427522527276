.BackGroundImage {
    background-image: url("../../../../assets/images/carwash-shopping-banner-2023.jpg");
    height: 10vh !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative !important;
    margin-top: -17px;
}

.ShadowBox {
    position: absolute;
    width: 100%;
    height: 100%;
    color: white;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.25); /* Black w/opacity/see-through */
}

.CaptionBox {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    filter: brightness(100%);
}

.CaptionTitle {
    font-weight: 800 !important;
    font-size: 1.5em !important;
    line-height: 1.1 !important;
}

.CaptionTagLine {
    font-weight: bold !important;
    font-size: 1em !important;
    line-height: 1.1 !important;
}

.MainWrapper {
    margin-top: 20px;
    text-align: center;
}

.MainTitle {
    font-weight: 900 !important;
    font-size: 1.8em !important;
    line-height: 1.7 !important;
}

.CategoryTitle {
    align-self: center;
    font-weight: bolder;
    font-size: 1.2em !important;
    line-height: 1.1 !important;
}

@media (max-width: 600px) {
    .CaptionTitle {
        font-size: 1em !important;
    }

    .CaptionTagLine {
        font-size: .75em !important;
    }
}
