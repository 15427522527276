.Bold {
    font-weight: bold !important;
    font-size: 1.5rem !important;
}

.ButtonIcons {
    display: none !important;
}

@media (max-width: 599px) {
    .CartButtonText {
       display: none;
    }

    .ButtonIcons {
        display: block !important;
    }

    .CartButton > span {
        margin: 0 !important;
    }
}
