.BackGroundImage {
    background-image: url("../../../assets/images/carwash-banner-2023.jpg");
    min-height: 300px !important;
    height: 88vh !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BlurredBox {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.35); /* Black w/opacity/see-through */
}

.CaptionBox {
    text-align: center;
    color: white;
}

.Title {
    font-weight: 900 !important;
    font-size: 22px !important;
    line-height: 1.1 !important;
}

.TagLine {
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 1.1 !important;
}

.LoginForm {
    background-color: white;
    padding: 20px;
    width: 80%;

    color: black;
}

/*sm*/
@media (min-width: 600px) {
    .BlurredBox {
        width: 70%;
        padding: 2.5em;
    }

    .Title {
        font-size: 35px !important;
        line-height: 1.3 !important;
    }

    .TagLine {
        font-size: 16px !important;
    }
}

/*md*/
@media (min-width: 900px) {
    .BlurredBox {
        width: 60%;
        padding: 2.5em;
    }

    .Title {
        font-size: 40px !important;
    }

    .TagLine {
        font-size: 20px !important;
        line-height: 1.3 !important;
    }
}

/*lg*/
@media (min-width: 1200px) {
    .BlurredBox {
        width: 50%;
        padding: 2.5em;
    }

    .Title {
        font-size: 45px !important;
    }

    .TagLine {
        font-size: 26px !important;
        line-height: 1.3 !important;
    }
}

/*xl*/
@media (min-width: 1536px) {
    .BlurredBox {
        width: 45%;
        padding: 2.5em;
    }

    .Title {
        font-size: 50px !important;
    }

    .TagLine {
        font-size: 32px !important;
        line-height: 1.3 !important;
    }
}
