.Card {
    height: 100% !important;
    max-height: 400px !important;
    min-height: 300px !important;
    box-shadow: 5px 5px 5px lightgrey !important;
    border: 1px solid lightgrey !important;
    border-radius: 15px !important;
}

.CardActionArea {
    height: 100% !important;
    flex-direction: column !important;
}

.Document {
    max-height: 200px !important;
    min-height: 200px !important;
}

.Page canvas {
    margin: 5px auto !important;
}
