.Wrapper {
    display: flex;
    place-content: center space-around;
    align-items: center;
    border-bottom: 1px solid black;
    padding: 10px;
}

.LeftContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    gap: 15px;
}

.RightContent {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    gap: 15px;
}

@media (max-width: 600px) {
    .Wrapper {
        flex-direction: column;
        height: auto;
        align-items: flex-start;
        gap: 15px;
    }

    .RightContent {
        align-items: center;
    }
}
