.Title {
    font-weight: 500 !important;
    margin-bottom: 12px !important;
    background: linear-gradient(45deg, var(--ec-primary), #0c3e81) !important;
    border-radius: 5px !important;
    color: white !important;
    padding: 5px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: relative !important;
    overflow: hidden !important;
}

.Card {
    box-shadow: 5px 5px 5px lightgrey !important;
    border: 1px solid lightgrey !important;
}

.Bubble {
    border: 2px solid rgba(255, 255, 255, 0.7) !important;
    border-radius: 50px !important;
    position: absolute !important;
}

.Dot {
    height: 10% !important;
    width: 25% !important;
    border-radius: 50px !important;
    background: rgba(255, 255, 255, 0.5) !important;
    transform: rotate(38deg) !important;
    position: absolute !important;
    top: 15% !important;
    right: 20% !important;
}
