.NavigationItem{
    margin: 10px 0 !important;
    box-sizing: border-box !important;
    width: 100% !important;
    text-decoration: none !important;
}

.NavigationItem a{
    color: black !important;
    text-decoration: none !important;
    width: 100% !important;
    box-sizing: border-box !important;
    display: inherit !important;
    align-items: center !important;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    background-color: #f7f3f3 !important;
    border-radius: 5px !important;
    align-items: center !important;
}

.NavigationItem a:hover,
.NavigationItem a.active {
    padding-left: 5px !important;
}
