.cart-item {
    display: flex !important;
    align-items: stretch !important;
    position: relative;
    border: 1px solid lightgrey !important;
    border-bottom: none !important;
    box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%) !important;
}

.content {
    display: flex !important;
    flex: 1;
    flex-direction: column;
    justify-content: space-around;
}

.cover {
    width: 125px !important;
    height: 100%;
}

.description {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    max-width: calc(100% - 35px);
}

.actions {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 8px !important;
}

@media (max-width: 550px) {
    .cart-item {
        flex-direction: column !important;
        align-items: center !important;
        padding: 3px;
    }

    .description {
        flex-direction: column !important;
        max-width: 100%;
    }

    .cover {
        border-radius: 8px !important;
        padding-top: 5px !important;
    }
}

.cartItemButton {
    color: var(--ec-primary) !important;
    width: 3rem !important;
    text-align: center !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    cursor: pointer !important;
    padding: 3px !important;
}

.cartItemButton:hover,
.cartItemButton:active {
    background-color: var(--ec-primary) !important;
    color: white !important;
}

.cartItemButton:disabled {
    background-color: lightgray !important;
    border-color: lightgray !important;
    color: white !important;
    cursor: not-allowed !important;
    pointer-events: auto !important;
}

.cart-item input::-webkit-outer-spin-button,
.cart-item input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
