.button {
    color: #373030 !important;
}

.button:hover,
.button:active {
    background-color: #e2e1e1 !important;
    color: var(--ec-secondary) !important;
}

.cart {
    color: inherit !important;
    background-color: inherit !important;
}

.bump {
    animation: bump 300ms ease-out !important;
}

@keyframes bump {
    0% {
        transform: scale(1) !important;
    }
    10% {
        transform: scale(0.9) !important;
    }
    30% {
        transform: scale(1.1) !important;
    }
    50% {
        transform: scale(1.15) !important;
    }
    100% {
        transform: scale(1) !important;
    }
}
