.Color {
    color: #373030 !important;
    background-color: #fff !important;
}

.ToolBar {
    justify-content: space-between !important;
}

.LeftContent {
    font-size: 1.25rem !important;
    font-family: "Montserrat", "Calibri", "Arial", sans-serif !important;
    font-weight: 500 !important;
    line-height: 1.6 !important;
    letter-spacing: 0.0075em !important;
    display: flex;
    flex: 1;
}

.RightContent {
    display: flex;
    align-items: center;
    flex: 2;
}

.Search {
    margin: 5px 10px !important;
    flex: 2 !important;
}

.Button {
    margin-right: 12px;
}

@media (max-width: 520px) {
    .RightContent {
        flex: 4;
    }

    .LeftContent {
        flex: 1;
    }

    .IconWords {
        display: none !important;
    }
}
