.MuiPaper-root.MuiAccordion-root:last-of-type {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.MuiPaper-root.MuiAccordion-root:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.MuiPaper-root.MuiAccordion-root.Mui-expanded:last-of-type {
    margin-bottom: 10px;
}

.MuiPaper-root.MuiAccordion-root.Mui-expanded:first-of-type {
    margin-top: 10px;
}
