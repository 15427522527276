body {
    font-family: 'Montserrat', "Calibri", "Arial", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    background-color: #f7f7f7;
    --ec-primary: #060644;
    --ec-secondary: #d8232a;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
